.container {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .title {
    font-family: 'Playfair Display', serif;
    font-size: 3em;
    margin-bottom: 50px;
    margin-top: 100px;
    font-weight: bold;
    font-style: italic;
  }
  
  .category-card {
    display: inline-block;
    width: 30%; 
    margin: 10px;
  }
  
  .category-name {
    font-family: 'Playfair Display', serif; 
    font-size: 1.5em;
    text-align: center;
    color: black;
    font-weight: bold;
  }
  
  .image-container {
    position: relative;
    height: 200px; 
    overflow: hidden;
  }
  
  .category-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .category-link {
    background-color: #f2f2f2; 
    padding: 10px;
    display: flex;
    justify-content: space-around;
  }

  .category-link a {
    text-decoration: none;
    color: blue;
  }